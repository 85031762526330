.video-react {
  max-height: 350px !important;
  height: 500px !important;
}
div#file-name {
  display: none;
}
div#header-bar {
  display: none;
}
#WACStatusBarContainer {
  display: none !important;
}
div#ChromelessStatusBar {
  display: none;
}
.p-button.p-button-icon-only.p-button-rounded {
  width: 1.4rem !important;
  height: 1.4rem !important;
  margin-left: 15px;
}

.docview .p-dialog-content {
  overflow: hidden;
  padding: 0px !important;
  margin: 0px;
}
.p-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #939393;
}